import Image from "next/legacy/image";

export default function LargeCTA(largeCTA) {
  return (
    largeCTA.largeCTA && (
      <>
        {
          largeCTA.largeCTA && largeCTA.largeCTA.type === 'light' && (
            <div id="large-cta-container" className="container-fluid light">
              <div id="large-cta-content">
                <div className="container xsmall-container">
                    <div dangerouslySetInnerHTML={{__html: largeCTA.largeCTA.content}}></div>
                </div>
              </div>
              <div id="large-cta-container-image" className="container-fluid">
                <Image
                    src={largeCTA.largeCTA.backgroundImage.sourceUrl ? largeCTA.largeCTA.backgroundImage.sourceUrl : ''}
                    layout='fill'
                    quality={100}
                    alt="Large Call to Action Background"
                  />
              </div>
            </div>
          )
        }
        {
          largeCTA.largeCTA && largeCTA.largeCTA.type === 'dark' && (
            <div id="large-cta-container" className="container-fluid dark" style={{  
              backgroundImage: largeCTA.largeCTA?.backgroundImage ? "url(" + largeCTA.largeCTA.backgroundImage.sourceUrl + ")" : ""
            }}>
              <div id="large-cta-content">
                <div className="container xsmall-container">
                    <div dangerouslySetInnerHTML={{__html: largeCTA.largeCTA.content}}></div>
                </div>
              </div>
            </div>
          )
        }
      </>
    )
  )
}