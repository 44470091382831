
export default function CTA(cta) {
    return (
      cta.cta && (
            <div id="call-to-action-container" className="container-fluid">
                <div className="container">
                    <div>
                        <div>
                            <div dangerouslySetInnerHTML={{__html: cta.cta.content}}></div>
                        </div>
                        <div>
                            <div dangerouslySetInnerHTML={{__html: cta.cta.button}}></div>
                        </div>
                    </div>
                </div>
            </div>
      )
    );
  }